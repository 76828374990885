.avatar-container{
    position: relative;
    display: inline-block;
}

.upload-img{
    cursor: pointer;
    max-width: 200px;
    max-height: 200px;
}

.upload-cation{
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    padding-top: 5px;
}

.upload-fileinput{
    display: none;
}

.upload-close-icon{
    position: absolute;
    z-index: 999;
    cursor: pointer;
    left: 10px;
    top: 10px;
}