/* @import '~line-awesome/dist/line-awesome/css/line-awesome.min.css'; */

.site-navbar {
    z-index: 199;
}

.lab.lg {
    font-size: 20px;
}

.bg-responsive {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.img-bank {
    width: 25px;
    height: 25px;
}

.img-bank.BAY {
    background-color: #fec43b;
}

.img-bank.TMB {
    background-color: #1279be;
}

.img-bank.KBANK {
    background-color: #138f2d;
}

.img-bank.KTB {
    background-color: #1ba5e1;
}

.img-bank.BBL {
    background-color: #1e4598;
}

.img-bank.SCB {
    background-color: #4e2e7f;
}

.img-bank.UOB {
    background-color: #00256b;
}

.text-line-through {
    text-decoration: line-through;
}

.main-form {
    min-height: 500px;
}

.label {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    padding: 3px 8px;
    text-shadow: none;
    border-radius: 0.25em;
    line-height: 1;
    white-space: nowrap;
}

.nav .label,
.ibox .label {
    font-size: 10px;
}

.badge {
    background-color: #d1dade;
    color: #5e5e5e;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 11px;
    font-weight: 600;
    padding-bottom: 4px;
    padding-left: 6px;
    padding-right: 6px;
    text-shadow: none;
    white-space: nowrap;
}

.label-primary,
.badge-primary {
    background-color: #1ab394;
    color: #FFFFFF;
}

.label-success,
.badge-success {
    background-color: #1c84c6;
    color: #FFFFFF;
}

.label-warning,
.badge-warning {
    background-color: #f8ac59;
    color: #FFFFFF;
}

.label-warning-light,
.badge-warning-light {
    background-color: #f8ac59;
    color: #ffffff;
}

.label-danger,
.badge-danger {
    background-color: #ed5565;
    color: #FFFFFF;
}

.label-info,
.badge-info {
    background-color: #23c6c8;
    color: #FFFFFF;
}

.label-inverse,
.badge-inverse {
    background-color: #262626;
    color: #FFFFFF;
}

.label-white,
.badge-white {
    background-color: #FFFFFF;
    color: #5E5E5E;
}

.label-white,
.badge-disable {
    background-color: #2A2E36;
    color: #8B91A0;
}